@import '../../../../scss/theme-bootstrap';

.tout-block {
  margin-#{$ldirection}: auto;
  margin-#{$rdirection}: auto;
  &__content,
  &__content-wrapper,
  &__media-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
  }
  &__media {
    display: block;
    width: 100%;
    height: auto;
    img,
    picture,
    video {
      //  aspect-ratio: 7 / 8;
    }
  }
  &__content-over-media {
    position: absolute;
    width: 100%;
    padding: 15px;
    @include breakpoint($landscape-up) {
      padding: 20px;
    }
    .tout-block-no-media & {
      position: relative;
    }
  }
  &__content-wrapper {
    overflow: hidden;
    z-index: 1;
    padding-top: 7px;
    &.body-1 {
      margin: 0;
    }
  }
  &__link-wrapper {
    display: inline-flex;
    margin-top: 8px;
    margin-bottom: 14px;
    .content-block__link {
      a.link {
        font-size: 17px;
      }
    }
  }
  .content-block__line {
    margin: 0;
  }
  .content-block__content {
    height: auto;
    flex-grow: 0;
  }
  .content-block__line--header {
    margin: 1px 0 0;
  }
  .content-block__line--content {
    margin: 3px 0 0;
  }
  p {
    margin-bottom: 0;
    line-height: inherit;
  }
  .mantle-custom-text {
    text-align: unset;
  }
  .headline-1,
  .headline-2 {
    line-height: 0.85;
  }
  .headline-6,
  .headline-5,
  .headline-4,
  .headline-3 {
    line-height: 1;
    letter-spacing: 0;
  }
  .body-1,
  .body-2 {
    line-height: 1.1;
    margin: 0;
  }
  .button {
    margin: 3px 0 0;
    height: 40px;
    line-height: 2;
  }
  .link {
    @include NeueHaasGrotesk-regular;
    text-transform: none;
  }
  .LPMcontainer[style],
  .sticky-footer-chat__link {
    @include font-smoothing;
    @include appearance(none);
    width: 100%;
    height: 100%;
    color: inherit !important;
    display: inherit !important;
    font-family: inherit !important;
    font-size: inherit !important;
    font-style: inherit !important;
    font-variant: inherit !important;
    list-style: inherit !important;
    letter-spacing: inherit !important;
    line-height: inherit !important;
    font-weight: inherit !important;
    margin: unset;
    padding: unset;
    text-transform: inherit !important;
    text-decoration: inherit !important;
    text-align: inherit !important;
    vertical-align: inherit !important;
    white-space: inherit !important;
    word-spacing: inherit !important;
    &:hover {
      background: inherit;
      border-color: inherit;
      color: inherit;
      text-decoration: inherit;
    }
    .sticky-livechat__text {
      width: 100%;
      height: 100%;
      top: 50%;
      transform: translate(0px, -50%);
      line-height: normal;
      position: relative;
    }
  }
}
